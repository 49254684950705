/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import { GrLinkNext } from 'react-icons/gr';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import momWithHerBaby from 'assets/images/mom-with-her-baby.webp';
import momCarryingBaby from 'assets/images/mom-carrying-baby.webp';
import momoKissingBaby from 'assets/images/mom-kissing-baby.webp';
import postpartumShape from 'assets/images/postpartum-shape.webp';
import postpartumShapeBg from 'assets/images/pregnancy-shape-bg.webp';
import smile from 'assets/images/smile.svg';
import tablet from 'assets/images/tablet.svg';
import headphones from 'assets/images/headphones.svg';
import { useGetParams } from '../utils/useGetParams';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { ROUTES } from 'utils/constants';
import { KEYS } from '../utils/constants';

import InfoScreen from '../InfoScreen';
import UserNameScreen from '../UserNameScreen';
import QuizStarter from '../QuizStarter';
import SolidButton from 'components/SolidButton';

const PostpartumPath = ({ startSurvey }) => {
  const [path] = useLocalStorage(KEYS.PATH);

  const { step, push } = useGetParams();
  const handleNext = () => {
    push(`${ROUTES.EVIE_ONBOARDING}?step=${step + 1}`);
  };

  return (
    <>
      {step === 2 && (
        <InfoScreen>
          <LazyLoadImage effect="opacity" src={momWithHerBaby} alt="Mom with her baby" className="info-screen__bgimg" />
          <section className="info-screen__footer">
            <div className="info-screen__footer_topbg" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon">
              <img src={smile} alt="smily" />
            </div>
            <h2 className="info-screen__footer_title">Recuperación posparto</h2>
            <p className="info-screen__footer_content">
              {`¿Pérdidas al estornudar? ¿dolor al tener relaciones? ¿sientes que algo "se te cae" ahí abajo?. Tu cuerpo
              posparto merece atención.`}
            </p>
          </section>
          <SolidButton className="evie-next-icon welcome-screen__next" onClick={handleNext}>
            <GrLinkNext />
          </SolidButton>
        </InfoScreen>
      )}
      {step === 3 && (
        <InfoScreen>
          <LazyLoadImage effect="opacity" src={momCarryingBaby} alt="Mom carry baby" className="info-screen__bgimg" />
          <section className="info-screen__footer">
            <div className="info-screen__footer_topbg" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon">
              <img src={tablet} alt="tablet" />
            </div>
            <h2 className="info-screen__footer_title">Controla tu suelo pélvico</h2>
            <p className="info-screen__footer_content">
              Recupera tus músculos pélvicos y abdomen para volver con confianza a tus actividades, desde el ejercicio
              hasta disfrutar tu vida íntima.
            </p>
          </section>
          <SolidButton className="evie-next-icon welcome-screen__next" onClick={handleNext}>
            <GrLinkNext />
          </SolidButton>
        </InfoScreen>
      )}
      {step === 4 && (
        <InfoScreen>
          <LazyLoadImage effect="opacity" src={momoKissingBaby} alt="Mom kissing baby" className="info-screen__bgimg" />
          <section className="info-screen__footer">
            <div className="info-screen__footer_topbg" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon">
              <img src={headphones} alt="headphones" />
            </div>
            <h2 className="info-screen__footer_title">Conecta con tu cuerpo</h2>
            <p className="info-screen__footer_content">
              Recibe guía de fisioterapeutas expertas en salud pélvica femenina y empieza a sentirte nuevamente
              imparable.
            </p>
          </section>
          <SolidButton className="evie-next-icon welcome-screen__next" onClick={handleNext}>
            <GrLinkNext />
          </SolidButton>
        </InfoScreen>
      )}
      {step === 5 && <UserNameScreen />}
      {step === 6 && (
        <QuizStarter background="postpartum">
          <section className="quiz-starter__content">
            <div className="quiz-starter__postpartum">
              <img src={postpartumShapeBg} alt="plant" className="quiz-starter__postpartum_bg" />
              <img src={postpartumShape} alt="woman with baby" className="quiz-starter__postpartum_img" />
            </div>
            <h2 className="quiz-starter__content_title">Sobre tu posparto</h2>
            <p className="quiz-starter__content_description">
              Responde las siguientes preguntas con la mayor claridad, usaremos tus respuestas para brindarte la mejor
              atención y tratamiento. Tu información es confidencial, todo lo que compartas se queda aquí.
            </p>
          </section>
          <section className="quiz-starter__footer">
            <SolidButton className="evie-survey" onClick={() => startSurvey(path)}>
              Iniciar cuestionario
            </SolidButton>
          </section>
        </QuizStarter>
      )}
    </>
  );
};

PostpartumPath.propTypes = {
  startSurvey: PropTypes.func.isRequired,
};

export default PostpartumPath;
