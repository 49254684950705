import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IoMdArrowBack } from 'react-icons/io';

const QuizStarter = ({ children, background }) => {
  const { goBack } = useHistory();
  return (
    <section className={`info-screen quiz-starter ${background}`}>
      <div className="info-screen__goback">
        <IoMdArrowBack className="info-screen__goback_icon" onClick={goBack} />
      </div>
      {children}
    </section>
  );
};

QuizStarter.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string.isRequired,
};

export default QuizStarter;
