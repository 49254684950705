import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { useDropzone } from 'react-dropzone';
import { AiOutlineCheckCircle, AiOutlinePlusSquare } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import { baseStyle, activeStyle, acceptStyle, rejectStyle } from 'utils/styles';

import SolidButton from 'components/SolidButton';

const baseStyleInput = {
  ...baseStyle,
  width: '50%',
  height: 120,
};

const FileInputSurveys = ({ formikProps, name, label, gif }) => {
  const { t } = useTranslation();
  const { values = {} } = { ...formikProps };
  const isfileName = values[name];
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (accepted) => {
      formikProps.setFieldValue(name, accepted);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyleInput,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  const files = (acceptedFiles || []).map((file) => (
    <li key={file.path} className="form__accepted-files_file">
      {file.path} <AiOutlineCheckCircle color="#46c2ad" size={20} />
    </li>
  ));

  return (
    <div className="question">
      <div className="question__validation">
        <label htmlFor={name}>{label}</label>
      </div>
      {!!gif && (
        <div className="question__gif">
          <LazyLoadImage effect="opacity" src={gif} alt="this is a Gif" />
        </div>
      )}
      <div className="form__file-container question__file">
        <div {...getRootProps({ style })} style={{ ...baseStyle }}>
          <input {...getInputProps()} />
          <p style={{ textAlign: 'center', marginBottom: 8, color: '#000', fontSize: 14 }}>
            {t('surveys.survey.fileUpload')}
          </p>
          <SolidButton onClick={open} className="evie-add">
            {t('button.upload')}
            <AiOutlinePlusSquare />
          </SolidButton>
        </div>
        {!isEmpty(files) ? (
          <ul className="form__accepted-files">{files}</ul>
        ) : (
          <ul className="form__accepted-files">
            <li className="form__accepted-files_file">
              {isfileName && (
                <>
                  {isfileName[0].path} <AiOutlineCheckCircle color="#46c2ad" size={20} />
                </>
              )}
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

FileInputSurveys.defaultProps = {
  formikProps: {},
  gif: '',
};

FileInputSurveys.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  gif: PropTypes.string,
  isRequired: PropTypes.bool.isRequired,
  formikProps: PropTypes.shape({
    setFieldValue: PropTypes.func,
    values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])),
  }),
};

export default FileInputSurveys;
