import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import pregnant from 'assets/images/pregnant.webp';
import pregnancyShape from 'assets/images/pregnancy-shape.webp';
import smile from 'assets/images/smile.svg';
import checkMark from 'assets/images/check-mark.svg';
import strawberry from 'assets/images/strawberry.svg';
import bodyShape from 'assets/images/body-shape.svg';
import plant from 'assets/images/plant.svg';

import PerkScreen from '../PerkScreen';

const PregnancyPlan = () => (
  <PerkScreen>
    <LazyLoadImage effect="opacity" src={pregnant} alt="pregnant woman" className="info-screen__bgimg perks" />
    <section className="info-screen__footer perks">
      <div className="info-screen__footer_topbg" />
      <div className="info-screen__footer_topbg second" />
      <div className="info-screen__footer_icon perks">
        <img src={pregnancyShape} alt="silhouette of pregnant woman" />
      </div>
      <h2 className="info-screen__footer_title">Embarazo y parto consciente</h2>
      <p className="info-screen__footer_content perks">Tu programa personalizado de embarazo</p>
      <div className="perk-screen__perkcard">
        <img className="perk-screen__perkcard_icon" src={smile} alt="smily" />
        <p className="perk-screen__perkcard_content">Valoración con fisioterapeutas</p>
      </div>
      <div className="perk-screen__perkcard">
        <img className="perk-screen__perkcard_icon" src={checkMark} alt="checkmark" />
        <p className="perk-screen__perkcard_content">Chat privado con expertas en salud</p>
      </div>
      <div className="perk-screen__perkcard">
        <img className="perk-screen__perkcard_icon" src={strawberry} alt="strawberry" />
        <p className="perk-screen__perkcard_content">Ejercicios de coordinación pélvica</p>
      </div>
      <div className="perk-screen__perkcard">
        <img className="perk-screen__perkcard_icon" src={bodyShape} alt="body shape" />
        <p className="perk-screen__perkcard_content">Rutina personalizadas para cada día</p>
      </div>
      <div className="perk-screen__perkcard">
        <img className="perk-screen__perkcard_icon" src={plant} alt="plant" />
        <p className="perk-screen__perkcard_content">Lecturas, audios y cápsulas interactivas</p>
      </div>
    </section>
  </PerkScreen>
);

export default PregnancyPlan;
