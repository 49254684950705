import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import evieHeart from 'assets/images/heart.png';
import pregnancy from 'assets/images/pregnancy.png';
import postpartum from 'assets/images/postpartum.png';
import incontinence from 'assets/images/incontinence.png';
import sexualPain from 'assets/images/sexual-pain.png';
import { CONDITIONS } from '../utils/constants';

import ContidionCard from '../ContidionCard';

const ContidionList = ({ conditions, selectCondition }) => {
  const { t } = useTranslation();

  return (
    <section className="issue-list">
      <header className="issue-list__header">
        <img src={evieHeart} alt="evie logo" className="welcome-screen__header_heart" />
        <h2 className="issue-list__header_title">{t('evieOnboarding.welcome.subtitle')}</h2>
        <p className="issue-list__header_content">{t('evieOnboarding.welcome.pathSelector.content')}</p>
      </header>
      <section>
        <ContidionCard
          isSelected={conditions.includes(CONDITIONS.PREGNANCY)}
          selectCondition={() => selectCondition(CONDITIONS.PREGNANCY)}
        >
          <img src={pregnancy} alt="pregnant woman" className="issue-card__image" />
          <h3 className="issue-card__title">{t('evieOnboarding.welcome.pathSelector.conditions.pregnancy')}</h3>
        </ContidionCard>
        <ContidionCard
          isSelected={conditions.includes(CONDITIONS.POSTPARTUM)}
          selectCondition={() => selectCondition(CONDITIONS.POSTPARTUM)}
        >
          <img src={postpartum} alt="pregnant woman" className="issue-card__image" />
          <h3 className="issue-card__title">{t('evieOnboarding.welcome.pathSelector.conditions.postpartum')}</h3>
        </ContidionCard>
        <ContidionCard
          isSelected={conditions.includes(CONDITIONS.INCONTINENCE)}
          selectCondition={() => selectCondition(CONDITIONS.INCONTINENCE)}
        >
          <img src={incontinence} alt="pregnant woman" className="issue-card__image" />
          <h3 className="issue-card__title">{t('evieOnboarding.welcome.pathSelector.conditions.incontinence')}</h3>
        </ContidionCard>
        <ContidionCard
          isSelected={conditions.includes(CONDITIONS.SEXUAL)}
          selectCondition={() => selectCondition(CONDITIONS.SEXUAL)}
        >
          <img src={sexualPain} alt="pregnant woman" className="issue-card__image" />
          <h3 className="issue-card__title">{t('evieOnboarding.welcome.pathSelector.conditions.sexualPain')}</h3>
        </ContidionCard>
      </section>
    </section>
  );
};

ContidionList.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectCondition: PropTypes.func.isRequired,
};

export default ContidionList;
