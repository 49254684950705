/* eslint-disable import/order */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { isEmpty } from 'ramda';
import { IoMdArrowBack } from 'react-icons/io';

import { ROUTES } from 'utils/constants';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { useGetParams } from '../utils/useGetParams';
import { KEYS } from '../utils/constants';
import signupLogo from 'assets/images/signup-logo.webp';

import SolidButton from 'components/SolidButton';
import TextInput from 'components/Surveys/FormControlSurveys/FormComponents/TextInput';

const SignupScreen = ({ signup }) => {
  const { goBack, push, step } = useGetParams();
  const signupStep = useRef(0);
  const firstStep = signupStep.current === 0;
  const secondStep = signupStep.current === 1;

  const [userName] = useLocalStorage(KEYS.USER_NAME);
  const [path] = useLocalStorage(KEYS.PATH);
  const [content] = useLocalStorage(KEYS.CONTENT);

  const handleBack = () => {
    signupStep.current -= 1;
    goBack();
  };
  const handleNext = () => {
    signupStep.current += 1;
    push(`${ROUTES.EVIE_ONBOARDING}?step=${step + 1}`);
  };

  const handleSubmit = (values) => {
    const { email, password } = values;
    signup({ email, name: userName, password, plan: path, contentSurvey: content });
  };

  return (
    <section className="username-screen">
      <div className="info-screen__goback">
        {secondStep && <IoMdArrowBack className="info-screen__goback_icon" onClick={handleBack} />}
      </div>
      <div className="sections__form username-screen__form">
        <img className="username-screen__form_logo" src={signupLogo} alt="Evie logo" />
        <h2 className="username-screen__form_label">Regístrate</h2>
        <p className="username-screen__form_description">
          Toma el control de tu salud pélvica hoy mismo. Empieza tu camino hacia una mejor persona y una mayor confianza
          en sí misma.
        </p>
        <Formik initialValues={{ email: '', password: '' }} onSubmit={handleSubmit}>
          {(formData) => {
            const isDisabledNext = isEmpty(formData.values.email);
            const isDisabled = isEmpty(formData.values.password);

            return (
              <Form>
                <div className="form-input username-screen__form_input">
                  {firstStep && <TextInput placeholder="Correo electrónico" formikProps={formData} name="email" />}
                  {secondStep && (
                    <TextInput placeholder="Contraseña" formikProps={formData} name="password" control="password" />
                  )}
                </div>
                {firstStep && (
                  <SolidButton
                    disabled={isDisabledNext}
                    onClick={handleNext}
                    type="button"
                    className={`evie-survey username-screen__form_nextbtn handle-sections ${
                      isDisabledNext ? 'evie-disabled' : ''
                    }`}
                  >
                    Siguiente
                  </SolidButton>
                )}
                {secondStep && (
                  <SolidButton
                    disabled={isDisabled}
                    type="submit"
                    className={`evie-survey username-screen__form_nextbtn handle-sections ${
                      isDisabled ? 'evie-disabled' : ''
                    }`}
                  >
                    Crea tu cuenta
                  </SolidButton>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};

SignupScreen.propTypes = {
  signup: PropTypes.func.isRequired,
};

export default SignupScreen;
