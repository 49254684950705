import React from 'react';
import PropTypes from 'prop-types';
import { IoMdArrowBack } from 'react-icons/io';

import evieLogo from 'assets/images/logo-letter.png';
import { useGetParams } from '../utils/useGetParams';

const InfoScreen = ({ children }) => {
  const { goBack } = useGetParams();
  return (
    <section className="info-screen">
      <div className="info-screen__goback">
        <IoMdArrowBack className="info-screen__goback_icon" onClick={goBack} />
      </div>
      <img src={evieLogo} alt="Evie logo" className="info-screen__logo" />
      {children}
    </section>
  );
};

InfoScreen.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InfoScreen;
