export const CONDITIONS = {
  PREGNANCY: 'pregnancy',
  POSTPARTUM: 'postpartum',
  INCONTINENCE: 'incontinence',
  SEXUAL: 'sexual',
};

export const ONBOARDING_STATUS = {
  WELCOME: 'welcome',
  SURVEY: 'survey',
  SIGN_UP: 'signUp',
  PLANS: 'plans',
};

export const KEYS = {
  PATH: 'path',
  CONDITIONS: 'conditions',
  USER_NAME: 'userName',
  ONBOARDING_STATUS: 'onboardingStatus',
  CONTENT: 'content',
};
