import React from 'react';
import PropTypes from 'prop-types';

import SolidButton from 'components/SolidButton';

const PerkScreen = ({ children }) => (
  <section className="perk-screen">
    <div className="info-screen__goback" />
    {children}
    <SolidButton className="evie-survey username-screen__form_nextbtn handle-sections">Empieza tu programa</SolidButton>
  </section>
);

PerkScreen.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PerkScreen;
