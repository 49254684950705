import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const InfoScreen = ({ label, content, gif }) => (
  <section className="info-screen-survey">
    <div className="question__gif">
      <LazyLoadImage effect="opacity" src={gif} alt="this is a Gif" width="100%" />
    </div>
    <h2 className="info-screen-survey__title">{label}</h2>
    <p className="info-screen-survey__content">{content}</p>
  </section>
);

InfoScreen.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  gif: PropTypes.string.isRequired,
};

export default InfoScreen;
