/* eslint-disable import/order */
import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import Loader from 'react-loader-spinner';
import { isEmpty } from 'ramda';

import { GET_SURVEY_BY_PROGRAM } from 'apollo/queries';
import { ONBOARDING_STATUS, KEYS } from 'components/evieOnboarding/utils/constants';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { useGetParams } from 'components/evieOnboarding/utils/useGetParams';
import { ROUTES } from 'utils/constants';
import { useIndividualSignup } from 'components/evieOnboarding/utils/useIndividualSignup';

import Layout from 'components/evieOnboarding/Layout';
import Welcome from 'components/evieOnboarding/Welcome';
import Survey from 'components/evieOnboarding/Survey';
import SignupScreen from 'components/evieOnboarding/SignupScreen';
import Plans from 'components/evieOnboarding/Plans';

import './evie-onboarding.scss';

const EvieOnboarding = () => {
  const { step, group, question, push } = useGetParams();
  const [status, setStatus] = useLocalStorage(KEYS.ONBOARDING_STATUS, ONBOARDING_STATUS.WELCOME);
  const [, setContent] = useLocalStorage(KEYS.CONTENT);

  const [getSurvey, { loading, error, data = {} }] = useLazyQuery(GET_SURVEY_BY_PROGRAM);
  const { getSurveyByProgram = {} } = data;

  const { individualSignup, spinner } = useIndividualSignup(setStatus);

  useEffect(() => {
    localStorage.clear();
    setStatus(ONBOARDING_STATUS.WELCOME);
    push(ROUTES.EVIE_ONBOARDING);
  }, []);

  useEffect(() => {
    if (!isEmpty(data) && !loading && !error) {
      setStatus(ONBOARDING_STATUS.SURVEY);
    }
  }, [data, loading, error]);

  const handleStartSurvey = (selectedPath) => {
    getSurvey({ variables: { planKey: selectedPath } });
    push(`${ROUTES.EVIE_ONBOARDING}?step=${step + 1}&group=${group}&question=${question}`);
  };

  const handleCompleteSurvey = async (surveyData) => {
    await setContent(surveyData);
    setStatus(ONBOARDING_STATUS.SIGN_UP);
    push(`${ROUTES.EVIE_ONBOARDING}?step=${step + 1}`);
  };

  return (
    <Layout>
      {status === ONBOARDING_STATUS.WELCOME && <Welcome startSurvey={handleStartSurvey} />}
      {status === ONBOARDING_STATUS.SURVEY && !isEmpty(data) && (
        <Survey survey={getSurveyByProgram} completeSurvey={handleCompleteSurvey} />
      )}
      {status === ONBOARDING_STATUS.SIGN_UP && <SignupScreen signup={individualSignup} />}
      {status === ONBOARDING_STATUS.PLANS && <Plans />}
      <Loader
        type="ThreeDots"
        color="#9b2c51"
        height={100}
        width={100}
        className="spinner"
        visible={loading || spinner}
      />
    </Layout>
  );
};

export default EvieOnboarding;
