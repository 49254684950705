import React from 'react';
import { Field } from 'formik';
import { v4 as uuid } from 'uuid';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const CheckboxGroup = (props) => {
  const { label, gif, name, options, isRequired, ...rest } = { ...props };
  const mapOptions = options.map((item) => {
    if (item.value) {
      return item;
    }
    return { value: item, label: item };
  });

  return (
    <div className="question">
      <div className="question__validation">
        <label htmlFor={name}>{label}</label>
      </div>
      {!!gif && (
        <div className="question__gif">
          <LazyLoadImage effect="opacity" src={gif} alt="this is a Gif" />
        </div>
      )}
      <Field id={name} name={name} {...rest}>
        {({ field }) => (
          <>
            {mapOptions.map((option) => (
              <label key={uuid()} className={`radio radio--${field.value.includes(option.value) && 'checked'}`}>
                {option.label}
                <input
                  type="checkbox"
                  id={option.value}
                  {...field}
                  value={option.value}
                  checked={field.value.includes(option.value)}
                />
                <span className="checkmark-box" />
              </label>
            ))}
          </>
        )}
      </Field>
    </div>
  );
};

export default CheckboxGroup;
