import React from 'react';
import PropTypes from 'prop-types';

import { useGetParams } from '../utils/useGetParams';

import WelcomeScreen from '../WelcomeScreen';
import PathSelector from '../PathSelector';
import PathController from '../PathController';

const Welcome = ({ startSurvey }) => {
  const { step } = useGetParams();

  return (
    <>
      {step === 0 && <WelcomeScreen />}
      {step === 1 && <PathSelector />}
      {step > 1 && step < 7 && <PathController startSurvey={startSurvey} />}
    </>
  );
};

Welcome.propTypes = {
  startSurvey: PropTypes.func.isRequired,
};

export default Welcome;
