import React from 'react';
import PropTypes from 'prop-types';

import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { CONDITIONS, KEYS } from '../utils/constants';

import PregnancyPath from '../PregnancyPath';
import PostpartumPath from '../PostpartumPath';
import PainPath from '../PainPath';

const PathController = ({ startSurvey }) => {
  const [path] = useLocalStorage(KEYS.PATH);

  switch (path) {
    case CONDITIONS.PREGNANCY:
      return <PregnancyPath startSurvey={startSurvey} />;
    case CONDITIONS.POSTPARTUM:
      return <PostpartumPath startSurvey={startSurvey} />;
    case CONDITIONS.INCONTINENCE:
    case CONDITIONS.SEXUAL:
      return <PainPath startSurvey={startSurvey} />;
    default:
      return null;
  }
};

PathController.propTypes = {
  startSurvey: PropTypes.func.isRequired,
};

export default PathController;
