import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import './FormComponents.scss';

const RangeInput = ({ label, gif, name, ...rest }) => {
  const { min = 0, max = 10 } = { ...rest };
  const length = max - min + 1;
  const range = [...new Array(length)].map((_, index) => index + min);

  const handleInputChange = ({ event, form, field }) => {
    form.setFieldValue(field.name, event.target.value);
  };

  return (
    <div className="question">
      <div className="question__validation">
        <label htmlFor={name}>{label}</label>
      </div>
      {!!gif && (
        <div className="question__gif">
          <LazyLoadImage effect="opacity" src={gif} alt="this is a Gif" />
        </div>
      )}
      <Field name={name}>
        {({ field, form }) => (
          <div className="evie-range">
            <div className="evie-range__numbers">
              {(range || []).map((item) => (
                <span key={item} className={item === Number(field.value) ? 'active' : ''}>
                  {item}
                </span>
              ))}
            </div>
            <input
              type="range"
              value={field.value || 0}
              min={min}
              max={max}
              onChange={(event) => handleInputChange({ event, form, field })}
              className="evie-range__input"
            />
          </div>
        )}
      </Field>
    </div>
  );
};

RangeInput.defaultProps = {
  gif: '',
};

RangeInput.propTypes = {
  label: PropTypes.string.isRequired,
  gif: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default RangeInput;
