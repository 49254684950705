import React from 'react';

import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { CONDITIONS, KEYS } from '../utils/constants';

import PregnancyPlan from '../PregnancyPlan';
import PostpartumPlan from '../PostpartumPlan';
import PainPlan from '../PainPlan';

const Plans = () => {
  const [path] = useLocalStorage(KEYS.PATH);

  switch (path) {
    case CONDITIONS.PREGNANCY:
      return <PregnancyPlan />;
    case CONDITIONS.POSTPARTUM:
      return <PostpartumPlan />;
    case CONDITIONS.INCONTINENCE:
    case CONDITIONS.SEXUAL:
      return <PainPlan />;
    default:
      return null;
  }
};

export default Plans;
