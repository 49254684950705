import { useMutation } from '@apollo/react-hooks';
import { isEmpty, pathOr } from 'ramda';

import showNotification from 'utils/showNotification';
import { INDIVIDUAL_SIGNUP } from 'apollo/mutations';
import { ROUTES } from 'utils/constants';
import { useToggle } from 'utils/hooks/useToggle';
import { ONBOARDING_STATUS } from './constants';
import { useGetParams } from './useGetParams';

export const useIndividualSignup = (setStatus) => {
  const { step, push } = useGetParams();
  const [individualSignup] = useMutation(INDIVIDUAL_SIGNUP);
  const [spinner, setSpinner] = useToggle();

  const handleIndividualSignup = async (userData) => {
    setSpinner();
    const response = await individualSignup({
      variables: {
        ...userData,
        source: 'web_flow',
      },
    });

    const errors = pathOr([], ['data', 'individualSignup', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Usuario creado exitosamente.`] : errors,
    });

    if (emptyErrors) {
      setStatus(ONBOARDING_STATUS.PLANS);
      push(`${ROUTES.EVIE_ONBOARDING}?step=${step + 1}`);
    }
  };

  return { individualSignup: handleIndividualSignup, spinner };
};
