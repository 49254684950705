import { useHistory } from 'react-router-dom';

import { queryParams } from 'utils/string';
import { convertType } from './convertType';

export const useGetParams = () => {
  const {
    goBack,
    push,
    location: { search },
  } = useHistory();
  const { step = 0, group = 1, question = 1 } = queryParams(search);
  const stepNumber = convertType(step);
  const groupNumber = convertType(group);
  const questionNumber = convertType(question);

  return { step: stepNumber, group: groupNumber, question: questionNumber, push, goBack };
};
