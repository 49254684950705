import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import './FormComponents.scss';

const TextInput = ({ label, gif, name, control, isRequired, ...rest }) => {
  const isBlockType = control === 'block';
  const TYPES = { text: 'text', numeric: 'number', password: 'password' };
  return (
    <div className="question">
      <div className="question__validation">
        <label htmlFor={name}>{label}</label>
      </div>
      {!!gif && (
        <div className="question__gif">
          <LazyLoadImage effect="opacity" src={gif} alt="this is a Gif" />
        </div>
      )}
      <Field
        {...rest}
        max={999999999}
        type={TYPES[control] || 'text'}
        id={name}
        name={name}
        className={`input ${isBlockType ? 'field-block' : ''}`}
      />
    </div>
  );
};

TextInput.defaultProps = {
  gif: '',
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  gif: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
};

export default TextInput;
