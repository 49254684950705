import React from 'react';
import PropTypes from 'prop-types';

const ContidionCard = ({ children, isSelected, selectCondition }) => (
  <button type="button" className="issue-card" onClick={selectCondition}>
    {children}
    <span className={`issue-card__checkmark ${isSelected ? 'selected' : ''}`} />
  </button>
);

ContidionCard.propTypes = {
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectCondition: PropTypes.func.isRequired,
};

export default ContidionCard;
