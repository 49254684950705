/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import { GrLinkNext } from 'react-icons/gr';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import pregnantWomen from 'assets/images/two-pregnant-women.webp';
import pregnantWoman from 'assets/images/pregnant-woman.webp';
import pregnantWoman1 from 'assets/images/pregnant-woman-1.webp';
import pregnancyShape from 'assets/images/pregnancy-shape.webp';
import pregnancyShapeBg from 'assets/images/pregnancy-shape-bg.webp';
import smile from 'assets/images/smile.svg';
import tablet from 'assets/images/tablet.svg';
import headphones from 'assets/images/headphones.svg';
import { useGetParams } from '../utils/useGetParams';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { ROUTES } from 'utils/constants';
import { KEYS } from '../utils/constants';

import InfoScreen from '../InfoScreen';
import UserNameScreen from '../UserNameScreen';
import QuizStarter from '../QuizStarter';
import SolidButton from 'components/SolidButton';

const PregnancyPath = ({ startSurvey }) => {
  const [path] = useLocalStorage(KEYS.PATH);

  const { step, push } = useGetParams();
  const handleNext = () => {
    push(`${ROUTES.EVIE_ONBOARDING}?step=${step + 1}`);
  };

  return (
    <>
      {step === 2 && (
        <InfoScreen>
          <LazyLoadImage effect="opacity" src={pregnantWomen} alt="Two pregnant women" className="info-screen__bgimg" />
          <section className="info-screen__footer">
            <div className="info-screen__footer_topbg" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon">
              <img src={smile} alt="smily" />
            </div>
            <h2 className="info-screen__footer_title">Prepárate para tu parto</h2>
            <p className="info-screen__footer_content">
              Aprende a pujar, técnicas de respiración, posturas de parto y reduce el riesgo de lesiones, sea parto
              vaginal o cesárea.
            </p>
          </section>
          <SolidButton className="evie-next-icon welcome-screen__next" onClick={handleNext}>
            <GrLinkNext />
          </SolidButton>
        </InfoScreen>
      )}
      {step === 3 && (
        <InfoScreen>
          <LazyLoadImage effect="opacity" src={pregnantWoman} alt="Pregnant woman" className="info-screen__bgimg" />
          <section className="info-screen__footer">
            <div className="info-screen__footer_topbg" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon">
              <img src={tablet} alt="tablet" />
            </div>
            <h2 className="info-screen__footer_title">Controla tu suelo pélvico</h2>
            <p className="info-screen__footer_content">
              Recibe ejercicios pélvicos personalizados para prevenir o tratar escapes de orina, dolor sexual,
              estreñimiento y muchos más.
            </p>
          </section>
          <SolidButton className="evie-next-icon welcome-screen__next" onClick={handleNext}>
            <GrLinkNext />
          </SolidButton>
        </InfoScreen>
      )}
      {step === 4 && (
        <InfoScreen>
          <LazyLoadImage effect="opacity" src={pregnantWoman1} alt="Pregnant woman" className="info-screen__bgimg" />
          <section className="info-screen__footer">
            <div className="info-screen__footer_topbg" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon">
              <img src={headphones} alt="headphones" />
            </div>
            <h2 className="info-screen__footer_title">Conecta con tu cuerpo</h2>
            <p className="info-screen__footer_content">
              Accede a fisioterapeutas expertas en salud pélvica femenina quienes te guiarán a sentirte fuerte y
              preparada para el parto.
            </p>
          </section>
          <SolidButton className="evie-next-icon welcome-screen__next" onClick={handleNext}>
            <GrLinkNext />
          </SolidButton>
        </InfoScreen>
      )}
      {step === 5 && <UserNameScreen />}
      {step === 6 && (
        <QuizStarter background="pregnancy">
          <section className="quiz-starter__content">
            <div className="quiz-starter__pregnancy">
              <img src={pregnancyShapeBg} alt="plant" className="quiz-starter__pregnancy_bg" />
              <img src={pregnancyShape} alt="silhouette of pregnant woman" className="quiz-starter__pregnancy_img" />
            </div>
            <h2 className="quiz-starter__content_title">Sobre tu embarazo</h2>
            <p className="quiz-starter__content_description">
              Responde las siguientes preguntas con la mayor claridad, usaremos tus respuestas para brindarte la mejor
              atención y tratamiento. Tu información es confidencial, todo lo que compartas se queda aquí.
            </p>
          </section>
          <section className="quiz-starter__footer">
            <SolidButton className="evie-survey" onClick={() => startSurvey(path)}>
              Iniciar cuestionario
            </SolidButton>
          </section>
        </QuizStarter>
      )}
    </>
  );
};

PregnancyPath.propTypes = {
  startSurvey: PropTypes.func.isRequired,
};

export default PregnancyPath;
