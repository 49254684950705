import React from 'react';
import { object, string } from 'yup';
import { Formik, Field, Form } from 'formik';
import { TextInput, NumberInput } from 'components/FormComponents';
import { requiredMessage, urlMessage } from 'utils/validationSchema';

const validationSchema = object().shape({
  gif: string().trim().url(urlMessage),
  min: string().required(requiredMessage),
  max: string().required(requiredMessage),
});

const ScaleForm = (props) => {
  const { onUpdateField, data, footer } = { ...props };
  return (
    <Formik initialValues={data} onSubmit={onUpdateField} validationSchema={validationSchema}>
      {({ setFieldValue }) => (
        <Form className="form">
          <Field type="text" label="Enunciado de la pregunta (es)" name="label" component={TextInput} />
          <Field type="text" label="Enunciado de la pregunta (en)" name="en" component={TextInput} />
          <Field type="text" label="Gif url" name="gif" component={TextInput} />
          <Field type="text" label="Mínimo" name="min" component={NumberInput} />
          <Field type="text" label="Máximo" name="max" component={NumberInput} />
          <Field
            name="required"
            render={({ field }) => (
              <div className="form__input-container assessment__checkbox__input">
                <input
                  {...field}
                  type="checkbox"
                  checked={field.value}
                  onChange={() => {
                    setFieldValue('required', !field.value);
                  }}
                />
                <label className="form__label">Obligatorio</label>
              </div>
            )}
          />
          <Field
            name="includeNA"
            render={({ field }) => (
              <div className="form__input-container assessment__checkbox__input">
                <input
                  {...field}
                  type="checkbox"
                  checked={field.value}
                  onChange={() => {
                    setFieldValue('includeNA', !field.value);
                  }}
                />
                <label className="form__label">Incluir N/A</label>
              </div>
            )}
          />
          <Field
            name="chart"
            render={({ field }) => (
              <div className="form__input-container assessment__checkbox__input">
                <input
                  {...field}
                  type="checkbox"
                  checked={field.value}
                  onChange={() => {
                    setFieldValue('chart', !field.value);
                  }}
                />
                <label className="form__label">Incluir gráfica</label>
              </div>
            )}
          />
          {footer}
        </Form>
      )}
    </Formik>
  );
};

export default ScaleForm;
