/* eslint-disable import/order */
import React from 'react';
import { GrLinkNext } from 'react-icons/gr';
import { Trans } from 'react-i18next';

import { ROUTES } from 'utils/constants';
import { useGetParams } from '../utils/useGetParams';
import evieHeart from 'assets/images/heart.png';
import learnWithUs from 'assets/images/learn-with-us.png';
import topknot from 'assets/images/topknot.png';

import SolidButton from 'components/SolidButton';

const WelcomeScreen = () => {
  const { push, step } = useGetParams();

  const handleNext = () => {
    push(`${ROUTES.EVIE_ONBOARDING}?step=${step + 1}`);
  };
  return (
    <section className="welcome-screen">
      <img src={learnWithUs} alt="learn with us" className="welcome-screen__text" />
      <header className="welcome-screen__header">
        <img src={evieHeart} alt="evie logo" className="welcome-screen__header_heart" />
        <h1 className="welcome-screen__header_title">
          <Trans components={{ br: <br /> }}>evieOnboarding.welcome.title</Trans>
        </h1>
      </header>
      <img src={topknot} alt="topknot" className="welcome-screen__knot" />
      <SolidButton className="evie-next-icon welcome-screen__next" onClick={handleNext}>
        <GrLinkNext />
      </SolidButton>
    </section>
  );
};

export default WelcomeScreen;
