/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { object, string, array } from 'yup';
import { Formik, Field, Form, FieldArray } from 'formik';
import { FaRegPlusSquare, FaRegTrashAlt } from 'react-icons/fa';
import { TextInput } from 'components/FormComponents';
import { requiredMessage, urlMessage } from 'utils/validationSchema';

const validationSchema = object().shape({
  gif: string().trim().url(urlMessage),
  options: array().of(string().trim().required(requiredMessage)),
  'en-options': array().of(string().trim().required(requiredMessage)),
});

const ListForm = (props) => {
  const { onUpdateField, data, footer } = { ...props };
  const { options, type: typeObj } = { ...data };

  const [scoreOptions, setScoreOptions] = useState({});
  const isPointed = ['pointed-multiple-selection'].includes(typeObj.type);

  useEffect(() => {
    if (isPointed && options) {
      let queryScoreOptions = {};
      options.forEach((option, index) => {
        queryScoreOptions = { ...queryScoreOptions, [index]: option.value };
      });
      setScoreOptions((prevScoreOptions) => ({ ...prevScoreOptions, ...queryScoreOptions }));
    }
  }, [isPointed, options]);

  const getInitialValues = (pointed) => {
    if (pointed) {
      return { ...data, options: (options || ['']).map(({ label }) => label) };
    }
    return {
      ...data,
      options: options || [''],
      'en-options': data['en-options'] || [''],
    };
  };

  const handleScoreOptions = ({ target: { value } }, currentIndex) => {
    setScoreOptions((prevScoreOptions) => ({
      ...prevScoreOptions,
      [currentIndex]: value,
    }));
  };

  const handleSubmit = (values) => {
    if (isPointed) {
      const updatedValues = {
        ...values,
        options: values.options.map((option, index) => ({ label: option, value: scoreOptions[index] || '0' })),
      };
      onUpdateField(updatedValues);
    } else {
      onUpdateField(values);
    }
  };

  return (
    <Formik initialValues={getInitialValues(isPointed)} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ values, setFieldValue }) => {
        const { options: formOptions = [], 'en-options': enOptions = [] } = values;
        return (
          <Form className="form">
            <Field type="text" label="Enunciado de la pregunta (es)" name="label" component={TextInput} />
            <Field type="text" label="Enunciado de la pregunta (en)" name="en" component={TextInput} />
            <Field type="text" label="Gif url" name="gif" component={TextInput} />
            <Field
              name="required"
              render={({ field }) => (
                <div className="form__input-container assessment__checkbox__input">
                  <input
                    {...field}
                    type="checkbox"
                    checked={field.value}
                    onChange={() => {
                      setFieldValue('required', !field.value);
                    }}
                  />
                  <label className="form__label">Obligatorio</label>
                </div>
              )}
            />
            {!isPointed && (
              <Field
                name="multi"
                render={({ field }) => (
                  <div className="form__input-container assessment__checkbox__input">
                    <input
                      {...field}
                      type="checkbox"
                      checked={field.value}
                      onChange={() => {
                        setFieldValue('multi', !field.value);
                      }}
                    />
                    <label className="form__label">Múltiples respuestas</label>
                  </div>
                )}
              />
            )}
            <Field
              name="chart"
              render={({ field }) => (
                <div className="form__input-container assessment__checkbox__input">
                  <input
                    {...field}
                    type="checkbox"
                    checked={field.value}
                    onChange={() => {
                      setFieldValue('chart', !field.value);
                    }}
                  />
                  <label className="form__label">Incluir gráfica</label>
                </div>
              )}
            />
            <hr />
            <FieldArray
              name="options"
              render={(arrayHelpers) => (
                <div>
                  <div className="list_options_add_button">
                    <p className="options_title">Opciones (es)</p>
                    <FaRegPlusSquare onClick={() => arrayHelpers.push('')} />
                  </div>

                  {formOptions.map((_, index) => (
                    <div key={index} className="input_field_trash">
                      <Field type="text" name={`options[${index}]`} component={TextInput} />
                      {isPointed && (
                        <input
                          value={scoreOptions[index]}
                          type="number"
                          step="any"
                          className="form__input-container form__input pointed-input"
                          onChange={(event) => handleScoreOptions(event, index)}
                        />
                      )}
                      <FaRegTrashAlt onClick={() => arrayHelpers.remove(index)} />
                    </div>
                  ))}
                </div>
              )}
            />
            <FieldArray
              name="en-options"
              render={(arrayHelpers) => (
                <div>
                  <div className="list_options_add_button">
                    <p className="options_title">Opciones (en)</p>
                    <FaRegPlusSquare onClick={() => arrayHelpers.push('')} />
                  </div>

                  {enOptions.map((_, index) => (
                    <div key={index} className="input_field_trash">
                      <Field type="text" name={`en-options[${index}]`} component={TextInput} />
                      {isPointed && (
                        <input
                          value={scoreOptions[index]}
                          type="number"
                          step="any"
                          className="form__input-container form__input pointed-input"
                          onChange={(event) => handleScoreOptions(event, index)}
                        />
                      )}
                      <FaRegTrashAlt onClick={() => arrayHelpers.remove(index)} />
                    </div>
                  ))}
                </div>
              )}
            />
            {footer}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ListForm;
